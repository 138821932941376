import React, { createContext, ReactNode, useContext } from "react";
import useData from "../hooks/useData";

interface ContextProviderProps {
  children: ReactNode;
}

export const AllContext = createContext<any>(undefined);

const GlobalProvider: React.FC<ContextProviderProps> = ({ children }: any) => {
  const data = useData();

  return <AllContext.Provider value={data}>{children}</AllContext.Provider>;
};

export default GlobalProvider;

export const useStore = ()=> useContext(AllContext)