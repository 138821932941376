export  const statusOptions = [
    { label: 'Present', value: 'present' },
    { label: 'Pending', value: 'pending' },
    { label: 'Completed', value: 'completed' },
    { label: 'Canceled', value: 'canceled' },
];

export const appointmentTypeOptions = [
    { id: 'new', label: 'New' },
    { id: 'old', label: 'Old' },
    { id: 'followup', label: 'Follow Up' },
  ];

export const genderOptions = [
    { id: 'male', label: 'Male' },
    { id: 'female', label: 'Female' }
];
export const maritalOptions = [
    { id: 'married', label: 'Married' },
    { id: 'unmarried', label: 'Unmarried' }
];

export  const ageTypes = ['years', 'months', 'days'];