import React from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const GoBack = ({link}:any) => {
    const navigate = useNavigate()
    return (
        <div>
             <div className='mb-2'>
                    <MdKeyboardBackspace className='text-primary cursor-pointer' size={25} onClick={() => { navigate(`${link}`) }} />
                </div>
        </div>
    );
};

export default GoBack;