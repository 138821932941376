import { useEffect, useMemo, useState } from 'react';
import { useFetch } from "../../../../hooks/useFetch";
import { payloadDateFormat } from "../../../../utils/helper";
import AppointmentCreateButton from "../../../AppointmentCreateButton/AppointmentCreateButton";
import FilterOption from "../../../FilterOption/FilterOption";
import AppointmentList from "../../../AppointmentList/AppointmentList";
import SidebarFilterMenu from "../../../SidebarFilterMenu/SidebarFilterMenu";
import { useStore } from '../../../../GlobalProvider/GlobalProvider';
import { useSelector } from 'react-redux';

const WaitingRoom = () => {
    //Necessary states initialize
    const {selectedStatus, setSelectedStatus,search, setSearch,fromDate, setFromDate,toDate, setToDate } = useStore();
    const [appointmentData, setAppointmentData] = useState<any[]>([]);
    const [update, setUpdate] = useState<boolean>(false);
    const [limitData] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const { user, userRoles, selectedRole,selectedDoctor } = useSelector((state:any) => state.auth);
    //Get appointment list with date range,status wise filter.
    const { data: appointments, loading, notFound } = useFetch(`/appointment/list?doctorId=${selectedDoctor?._id}&userRoleId=${selectedRole?._id}&per_page=${limitData}&current_page=${currentPage}${search ? `&search=${search}` : ""}${selectedStatus ? `&status=${selectedStatus}` : ""}${fromDate && toDate ? `&startDate=${payloadDateFormat(fromDate)}` : ""}${fromDate && toDate ? `&endDate=${payloadDateFormat(toDate)}` : ""}`, [update]);
    //Set appointment data in a state
    useEffect(() => {
        if (notFound) {
            setAppointmentData([]);
        }
        if (appointments) {
            setAppointmentData(appointments?.appointments);
            setTotalPages(Math.ceil(appointments?.total / limitData));
        }
    }, [appointments, notFound, limitData]);

    // useMemo(()=>{
    //     if(selectedDoctor?._id){
    //         console.log("doctor change",selectedDoctor)
    //     }
    // },[selectedDoctor])
    //Pagination
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setUpdate(!update);
    };
    return (
        <div>
            <AppointmentCreateButton/>
            <FilterOption setShowFilter={setShowFilter} setSearch={setSearch} search={search} setCurrentPage={setCurrentPage}/>
            <AppointmentList appointmentData={appointmentData} loading={loading} update={update} setUpdate={setUpdate} totalPages={totalPages} handlePageChange={handlePageChange} currentPage={currentPage}/>
            <SidebarFilterMenu showFilter={showFilter}  setShowFilter={ setShowFilter} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} isStatus={true}/>
        </div>
    );
};

export default WaitingRoom;