import React, { useRef } from "react";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useStore } from "../../GlobalProvider/GlobalProvider";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeAuthInfo } from "../../store/slices/authSlice";

const RightNavbarMenu = () => {
  const { setToken, setUserProfile } = useStore();
  const { user  } = useSelector((state: any) => state.auth);
  const navigate = useNavigate()
  const menuLeft = useRef<any>(null);
  const toast = useRef<any>(null);
  const dispatch = useDispatch()
  const items = [
    // {
    //   label: "Profile",
    //   icon: "pi pi-refresh",
    //   command: () => {
    //     navigate('/profile')
    //   },
    // },
    {
      label: user?.name||"Staff",
      icon: "pi pi-refresh",
      command: () => {
      },
    },
    {
      label: "Logout",
      icon: "pi pi-times",
      command: () => {
        setToken('')
        setUserProfile(null)
        navigate('/login')
        dispatch(removeAuthInfo())
        localStorage.clear()
      },
    },
  ];

  
  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast}></Toast>
      <Menu model={items} popup ref={menuLeft} id="popup_menu_left" className="w-[150px] ml-auto mt-4 text-sm" />
      <Avatar
        className="ml-4 lg:ml-8"
        label="P"
        onClick={(event) => menuLeft.current.toggle(event)}
        style={{ backgroundColor: "#22c55e", color: "#ffffff" }}
        shape="circle"
      />
    </div>
  );
};
export default RightNavbarMenu;





