import React, {useEffect, useState} from "react";
import "./vital-signs.scss";
import {Dialog} from "primereact/dialog";
import VitalSignForm from "./vital-signs-form/vital-sign-form";

const VitalSigns = ({setShow, show, vitals,handleVitalsSubmit}) => {

    return (

        <Dialog
            visible={show}
            style={{width: "40rem"}}
            headerClassName="text-800 font-semibold text-xl"
            header={"Vitals"}
            breakpoints={{"960px": "75vw", "640px": "90vw"}}
            onHide={() => {
                setShow(false)
            }}
        >
            <VitalSignForm handleVitalsSubmit={handleVitalsSubmit} setVisitDetails={{}} visitDetails={{}}
                           updateState={{}}
                           setShow={setShow}
                           previousVitals={vitals}></VitalSignForm>
        </Dialog>
    )
        ;
};

export default VitalSigns;


