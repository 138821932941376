import "@szhsin/react-menu/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import "@szhsin/react-menu/dist/transitions/slide.css";
import Language from "../RightNavbarMenu/RightNavbarMenu";
import { useStore } from "../../GlobalProvider/GlobalProvider";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDoctor, setSelectedRole } from "../../store/slices/authSlice";
import { useMemo } from "react";

const Header = () => {
    const { user, userRoles, selectedRole, selectedDoctor } = useSelector((state: any) => state.auth);
    const dispatch = useDispatch()
    const handleSelect = (value: any) => {
        dispatch(setSelectedRole(JSON.parse(value)))
    }

    const handleSelectDoctor = (value: any) => {
        dispatch(setSelectedDoctor(JSON.parse(value)))
    }


    // useMemo(() => {

    //     if (selectedRole?._id && selectedRole?.parentUsers?.length > 0) {
    //         dispatch(setSelectedDoctor(selectedRole?.parentUsers[0]))
    //     }
    // }, [selectedRole?._id])
    return (
        <header className="p-2 border-b w-full grid grid-cols-12 px-4 bg-white">
            <div className="col-span-2 lg:col-span-3 flex items-center">
                <Link to={"/"}>
                    <div className="w-50 mx-auto">
                        {/*<img*/}
                        {/*  src={require("../../assets/logo/logo.jpg")}*/}
                        {/*  alt="logo"*/}
                        {/*  className="w-full"*/}
                        {/*/>*/}
                        <p style={{ fontWeight: "800", color: "#21C55D" }}>Shafa Care</p>
                    </div>
                </Link>
            </div>
            <div className="col-span-8 lg:col-span-5 flex items-center flex-wrap">
                <select
                    style={{ width: "100%", border: ".1px solid black", outline: "none" }}
                    onChange={(e) => {
                        handleSelect(e.target.value)
                    }}
                    value={JSON.stringify(selectedRole)}
                >
                    {
                        userRoles?.length > 0 && userRoles?.map((data: any, index: number) => {
                            return <option onClick={() => { handleSelect(data) }} key={index} value={JSON.stringify(data)}>{data?.location?.name},{selectedRole?.location?.organization?.name}</option>
                        })
                    }
                </select>{" "}

                <select
                    style={{ width: "100%", border: ".1px solid black", outline: "none" }}
                    onChange={(e) => {
                        handleSelectDoctor(e.target.value)
                    }}
                    value={JSON.stringify(selectedDoctor)}
                >
                    {
                        selectedRole?.parentUsers?.length > 0 && selectedRole?.parentUsers?.map((data: any, index: number) => {
                            return <option onClick={() => { handleSelectDoctor(data) }} key={index} value={JSON.stringify(data)}>{data?.name}</option>
                        })
                    }
                </select>{" "}
            </div>
            {/* <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="doctorId">Doctor <span
                            className='text-red-500'>*</span></label>
                        <select
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                            id="doctorId"
                            name="doctorId"
                            value={formData.doctorId}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Doctor</option>
                            {doctorList?.map((doctor: any) => (
                                <option key={doctor._id} value={doctor._id}>
                                    {doctor.name}
                                </option>
                            ))}
                        </select>
                    </div> */}
            <div className="col-span-2 lg:col-span-4 flex items-center justify-end">
                <Language />
            </div>
        </header>
    );
};

export default Header;
