import React, { useEffect, useState } from 'react';
import { useFetch } from "../../../../hooks/useFetch";
import { payloadDateFormat } from "../../../../utils/helper";
import AppointmentCreateButton from '../../../AppointmentCreateButton/AppointmentCreateButton';
import FilterOption from '../../../FilterOption/FilterOption';
import AppointmentList from '../../../AppointmentList/AppointmentList';
import SidebarFilterMenu from '../../../SidebarFilterMenu/SidebarFilterMenu';
import { useStore } from '../../../../GlobalProvider/GlobalProvider';
import { useSelector } from 'react-redux';

const CompletedAppointment = () => {
    //Necessary state 
    const { completedFromDate, setCompletedFromDate,
        completedToDate, setCompletedToDate, completedSearch, setCompletedSearch } = useStore();
    const [appointmentData, setAppointmentData] = useState<any[]>([]);
    const [update, setUpdate] = useState<boolean>(false);
    const [limitData] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [showFilter, setShowFilter] = useState(false);
    const { user, userRoles, selectedRole, selectedDoctor } = useSelector((state: any) => state.auth);

    const { data: appointments, loading, notFound } = useFetch(`/appointment/list?doctorId=${selectedDoctor?._id}&userRoleId=${selectedRole?._id}&per_page=${limitData}&current_page=${currentPage}&status=completed${completedFromDate && completedToDate ? `&startDate=${payloadDateFormat(completedFromDate)}` : ""}${completedFromDate && completedToDate ? `&endDate=${payloadDateFormat(completedToDate)}` : ""}${completedSearch ? `&search=${completedSearch}` : ""}`, [update]);

    useEffect(() => {
        if (notFound) {
            setAppointmentData([]);
        }
        if (appointments) {
            setAppointmentData(appointments?.appointments);
            setTotalPages(Math.ceil(appointments?.total / limitData));
        }
    }, [appointments, notFound, limitData]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setUpdate(!update);
    };
    return (

        <div>
            <AppointmentCreateButton />
            <FilterOption setShowFilter={setShowFilter} setSearch={setCompletedSearch} search={completedSearch} setCurrentPage={setCurrentPage} />
            <AppointmentList appointmentData={appointmentData} loading={loading} update={update} setUpdate={setUpdate} totalPages={totalPages} handlePageChange={handlePageChange} currentPage={currentPage} />
            <SidebarFilterMenu showFilter={showFilter} setShowFilter={setShowFilter} setFromDate={setCompletedFromDate} setToDate={setCompletedToDate} fromDate={completedFromDate} toDate={completedToDate} isStatus={false} />
        </div>


    );
};

export default CompletedAppointment;