
import React, {useEffect} from 'react';
import {ageTypes, appointmentTypeOptions, genderOptions, maritalOptions} from "../../utils/staticData";

const CreateNewAppointment = ({  formData, handleSubmit, locationData, doctorList, setFormData,setDoctorList}:any) => {
    const handleChange = (e: any) => {
        const { name, value } = e.target;

        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: prevFormData[name] === value ? '' : value
        }));
    };

    const handleLocationChange = (e: any) => {
        const { name, value } = e.target;
        let selectedLocation = locationData[e.target?.selectedIndex - 1] || {};
        setDoctorList(selectedLocation?.doctors || []);
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value
        }));
    };

 
    useEffect(() => {
        if (locationData?.length > 0) {
            const firstLocation = locationData[0];
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                visitLocation: firstLocation?._id || '',
                doctorId:firstLocation?.doctors[0]?._id || ''
            }));
            setDoctorList(firstLocation?.doctors || []);
        }
    }, [locationData, setFormData, setDoctorList]);
    return (
        <div>
            <div className="p-4 bg-white shadow-lg rounded-md">

                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="name">Name <span
                            className='text-red-500'>*</span></label>
                        <input
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="patientId">Patient Id</label>
                        <input
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                            type="text"
                            id="patientId"
                            name="patientId"
                            disabled={true}
                            value={formData.patientId}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="phoneNumber">Phone Number <span
                            className='text-red-500'>*</span></label>
                        <input
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="email">Email </label>
                        <input
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <div className='flex items-center gap-6'>
                            <div className="mb-4 w-full">
                                <label className="block text-sm font-medium mb-1" htmlFor="age">Age <span
                                    className='text-red-500'>*</span></label>
                                <input
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none"
                                    type="number"
                                    id="age"
                                    name="age"
                                    value={formData.age}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="mb-4 w-full">
                                <label className="block text-sm font-medium mb-1" htmlFor="ageType">Age Type</label>
                                <select
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none"
                                    id="ageType"
                                    name="ageType"
                                    value={formData.ageType}
                                    onChange={handleChange}
                                >
                                    {ageTypes?.map((type) => (
                                        <option key={type} value={type}>
                                            {type.charAt(0).toUpperCase() + type.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-medium mb-1" htmlFor="dateOfBirth">Date of Birth <span
                                className='text-red-500'>*</span></label>
                            <input
                                className="w-full px-3 py-2 border rounded-md focus:outline-none"
                                type="date"
                                id="dateOfBirth"
                                name="dateOfBirth"
                                value={formData.dateOfBirth}
                                onChange={handleChange}
                            />
                        </div>
                    </div>


                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="name">Address</label>
                        <textarea
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                            rows={2}
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                        />
                    </div>


                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="bloodGroup">Blood Group</label>
                        <input
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                            type="text"
                            id="bloodGroup"
                            name="bloodGroup"
                            value={formData.bloodGroup}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="gender">Gender <span
                            className='text-red-500'>*</span></label>
                        <div className="flex items-center">
                            {genderOptions?.map(option => (
                                <label key={option.id} className="mr-4">
                                    <input
                                        className="mr-2 focus:outline-none"
                                        type="checkbox"
                                        name="gender"
                                        value={option.id}
                                        checked={formData.gender?.toLowerCase() === option?.id?.toLowerCase()}
                                        onChange={handleChange}
                                    />
                                    {option.label}
                                </label>
                            ))}
                        </div>
                    </div>


                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="maritalStatus">Marital Status</label>
                        <div className="flex items-center">
                            {maritalOptions?.map(option => (
                                <label key={option.id} className="mr-4">
                                    <input
                                        className="mr-2"
                                        type="checkbox"
                                        name="maritalStatus"
                                        value={option.id}
                                        checked={formData.maritalStatus?.toLowerCase() === option.id?.toLowerCase()}
                                        onChange={handleChange}
                                    />
                                    {option.label}
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="visitLocation">Visit Location <span
                            className='text-red-500'>*</span></label>
                        <select
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                            id="visitLocation"
                            name="visitLocation"
                            value={formData.visitLocation}
                            required
                            onChange={handleLocationChange}
                        >
                            <option value="">Select Location</option>
                            {locationData?.map((location: any) => (
                                <option key={location._id} value={location._id}>
                                    {location.name}
                                </option>
                            ))}
                        </select>
                    </div> */}

                    {/* <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="doctorId">Doctor <span
                            className='text-red-500'>*</span></label>
                        <select
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                            id="doctorId"
                            name="doctorId"
                            value={formData.doctorId}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Doctor</option>
                            {doctorList?.map((doctor: any) => (
                                <option key={doctor._id} value={doctor._id}>
                                    {doctor.name}
                                </option>
                            ))}
                        </select>
                    </div> */}

                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="appointmentDate">Appointment
                            Date <span className='text-red-500'>*</span></label>
                        <input
                            type="date"
                            value={formData?.appointmentDate}
                            name="appointmentDate"
                            onChange={handleChange}
                            className="border rounded px-3 py-2 focus:outline-none "
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="appointmentType">Appointment
                            type <span className='text-red-500'>*</span></label>
                        <div className="flex items-center">
                            {appointmentTypeOptions?.map(option => (
                                <label key={option.id} className="mr-4">
                                    <input
                                        className="mr-2"
                                        type="checkbox"
                                        name="appointmentType"
                                        value={option.id}
                                        checked={formData.appointmentType === option.id}
                                        onChange={handleChange}
                                    />
                                    {option.label}
                                </label>
                            ))}
                        </div>
                    </div>

                    <button className="w-28 px-3 py-2 bg-green-500 text-white rounded-md" type="submit">Create</button>
                </form>
            </div>
        </div>
    );
};

export default CreateNewAppointment;