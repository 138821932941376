import React from 'react';
import {CiSquarePlus} from "react-icons/ci";
import {useNavigate} from "react-router-dom";

const AppointmentCreateButton = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className='flex justify-end items-center pb-4'>
                <CiSquarePlus className='text-primary cursor-pointer' size={40} onClick={() => {
                    navigate('/create-appointment')
                }}/>
            </div>
        </div>
    );
};

export default AppointmentCreateButton;