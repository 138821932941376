import React from 'react';
import {Dialog} from "primereact/dialog";
import {inputHandler} from "../../../utils/helper";

const VisitModal = ({showVisit,setShowVisit,handlevisitSubmit,visitData,setVisitData,loading}:any) => {
    return (
        <div>
            <Dialog
                visible={showVisit}
                style={{ width: "40rem" }}
                headerClassName="text-800 font-semibold text-xl"
                header={"Chief complaints"}
                breakpoints={{ "960px": "75vw", "640px": "90vw" }}
                onHide={() => {
                    setShowVisit(false)
                }}
            >
                <form onSubmit={handlevisitSubmit}>

                    <div className="p-4">
                        <div className="mb-4">
                            <label htmlFor="reasonForVisit" className="block text-gray-700 mb-2">
                                Reason for Visit
                            </label>
                            <textarea
                                id="reasonForVisit"
                                name="reasonForVisit"
                                value={visitData?.reasonForVisit}
                                onChange={(e) => inputHandler(e, visitData, setVisitData)}
                                className="w-full p-2 border border-primary rounded focus:outline-none"

                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="historyOfPresetIllness" className="block text-gray-700 mb-2">
                                History of Present Illness
                            </label>
                            <textarea
                                id="historyOfPresetIllness"
                                name="historyOfPresetIllness"
                                value={visitData?.historyOfPresetIllness}
                                onChange={(e) => inputHandler(e, visitData, setVisitData)}
                                className="w-full p-2 border border-primary rounded focus:outline-none"

                            />
                        </div>
                    </div>


                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="px-4 py-2 mr-2 bg-gray-400 text-white rounded-md"
                            onClick={() => setShowVisit(false)}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-primary text-white rounded-md"
                            disabled={loading}
                        >
                            Save
                        </button>
                    </div>
                </form>
            </Dialog>
        </div>

    );
};

export default VisitModal;