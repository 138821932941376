import React from 'react';
import { Dialog } from "primereact/dialog";
import { formatDate } from "../../../utils/helper";

const PatientUpdateModal = ({
    showPatientModal,
    setShowPatientModal,
    handlePatientSubmit,
    setPatientData,
    patientData, loading
}: any) => {
    const handlePatientInputChange = (e: any) => {
        const { name, value } = e.target;
        setPatientData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };
    return (
        <div>
            <Dialog
                visible={showPatientModal}
                style={{ width: "40rem" }}
                headerClassName="text-800 font-semibold text-xl"
                header={"Update Patient"}
                breakpoints={{ "960px": "75vw", "640px": "90vw" }}
                onHide={() => {
                    setShowPatientModal(false)
                    setPatientData(null)
                }}
            >
                <form onSubmit={handlePatientSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Name</label>
                        <input
                            type="text"
                            name="name"
                            defaultValue={patientData?.name}
                            onChange={handlePatientInputChange}
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Phone</label>
                        <input
                            type="text"
                            name="phone"
                            defaultValue={patientData?.phone}
                            onChange={handlePatientInputChange}
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                        />
                    </div>


                    <div className="mb-4">
                        <label className="block text-gray-700">DOB</label>
                        <input
                            type="date"
                            name="dob"
                            defaultValue={formatDate(patientData?.dob)}
                            onChange={handlePatientInputChange}
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                        />
                    </div>


                    <div className="mb-4">
                        <label className="block text-gray-700">Gender</label>
                        <input
                            type="text"
                            name="gender"
                            defaultValue={patientData?.gender}
                            onChange={handlePatientInputChange}
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                        />
                    </div>

            
                    <div className="flex justify-start">
                        <button
                            type="submit"
                            className="px-4 py-2 bg-primary text-white rounded-md"
                            disabled={loading}
                        >
                            Update
                        </button>
                    </div>
                </form>
            </Dialog>
        </div>
    );
};

export default PatientUpdateModal;