import React from 'react'

const Error = () => {
  return (
    <div className='h-screen w-full flex items-center justify-center bg-white'>
        <div className="w-[200px]">
            <img src={require("../../assets/error/error.jpg")} alt="" className="w-full" />
        </div>
    </div>
  )
}

export default Error