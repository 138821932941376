import { useEffect, useState, useMemo } from "react";
import {useStore} from "../GlobalProvider/GlobalProvider";
import { baseUrl } from "../utils/helper";

export const useFetch = (
    endpoint: string,
    dependencies: any[] = [],
    condition: boolean = false,
) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [data, setData] = useState<any>();
    const [totalData, setTotalData] = useState<number>(0);
    const [notFound, setNotfound] = useState(false);
    const { token } = useStore();
    const memoizedFetchData = useMemo(() => {
        let count = 0;
        return async () => {
            if (!token) {
                return;
            }

            // condition
            if (condition) {
                return;
            }

            try {
                count = count + 1;
                // fetch one time
                if (count > 1) {
                    return;
                }
                setLoading(true);
                const res = await fetch(`${baseUrl}${endpoint}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }, );

                if (res.status === 204) {
                    setNotfound(true);
                    setData([]);
                    return;
                }
                const resData = await res.json();
                if (res.ok && res.status !== 204) {
                    return resData;
                } else {
                    return [];
                }
            } catch (e: any) {
                setError(e.message);
                return [];
            } finally {
                setLoading(false);
            }
        };

    }, [endpoint, condition,  token, ...dependencies]);

    useEffect(() => {
        const getData = async () => {
            const fetchData = memoizedFetchData();
            const processedData = await fetchData;
            if (processedData?.total) {
                setTotalData(processedData.total);
            }

            setData(processedData);
        };

        getData().then();
    }, [memoizedFetchData]);

    return { loading, error, data, setData, totalData, notFound,};
};
