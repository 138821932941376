import React from 'react';
import Logo from '../Logo/Logo';
import { GrLinkedinOption } from "react-icons/gr";
import { BsInstagram, BsPinterest } from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
    return (
        <div>
            <div className="">
                <footer className="flex flex-col justify-center">
                    <div
                        className="flex items-center justify-center"
                    >
                        {/*<Logo url={require("../../assets/logo/logo.jpg")} />*/}
                        <p style={{fontWeight: "800", color: "#21C55D"}}>Shafa Care</p>
                    </div>


                    <div className="text-center mb-2 cursor-pointer">
                        <h5 className="">Follow us</h5>
                        <div className="flex items-center justify-center mt-1">
                            <div className="flex">
                                <button
                                    style={{ height: "25px", width: "25px" }}

                                    className=" rounded-full mx-2 border-0 bg-blue-600 text-white flex items-center justify-center"
                                >
                                    <FaFacebookF size={12} />
                                </button>
                                <button
                                    style={{ height: "25px", width: "25px" }}

                                    className=" rounded-full mx-2 border-0 bg-red-600 text-white flex items-center justify-center"
                                >
                                    <BsInstagram size={12} />
                                </button>
                                <button
                                    style={{ height: "25px", width: "25px" }}

                                    className=" rounded-full mx-2 border-0 bg-cyan-600 text-white flex items-center justify-center"
                                >
                                    <FiTwitter size={12} />
                                </button>
                                <button
                                    style={{ height: "25px", width: "25px" }}

                                    className=" rounded-full mx-2 border-0 bg-red-600 text-white flex items-center justify-center"
                                >
                                    <AiOutlineYoutube size={12} />
                                </button>
                                <button
                                    style={{ height: "25px", width: "25px" }}

                                    className=" rounded-full mx-2 border-0 bg-blue-600 text-white flex items-center justify-center"
                                >
                                    <GrLinkedinOption size={12} />
                                </button>
                                <button
                                    style={{ height: "25px", width: "25px" }}
                                    className=" rounded-full mx-2 border-0 bg-red-600 text-white flex items-center justify-center"
                                >
                                    <BsPinterest size={12} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <p className="text-center text-gray-700 font-normal text-sm p-2 ">
                        Copyright 2024 © <span className="text-primary">Shafa Care</span> All rights reserved.
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default Footer;