import React from 'react';
import {BsFilterSquare} from "react-icons/bs";
import {InputText} from "primereact/inputtext";

const FilterOption = ({setShowFilter,search,setCurrentPage,setSearch}:any) => {
    return (
        <div>
            <div className="grid grid-cols-12">
                <div className="col-span-2 lg:col-span-2 flex items-center">
                    <BsFilterSquare className="cursor-pointer text-primary" size={25} onClick={() => {
                        setShowFilter(true)
                    }}/>
                </div>

                <div className="col-span-10 lg:col-span-10 flex items-center justify-end">
                    <div>
                        <InputText
                            value={search}
                            name="name"
                            placeholder="Type your search..."
                            className="w-full p-2 border"
                            onChange={(e) => {
                                setSearch(e.target.value)
                                setCurrentPage(1)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterOption;