import { Dialog } from 'primereact/dialog';
import React from 'react';
import {formatDate, inputHandler} from '../../../utils/helper';
import { appointmentTypeOptions, statusOptions } from '../../../utils/staticData';

const AppointmentUpdateModal = ({ showAppointmentModal,
    setShowAppointmentModal,
    handleAppointmentSubmit, appointmentData, setAppointmentData, loading }: any) => {

    //
    // const handleAppointmentInputChange = (e: any) => {
    //     const { name, value } = e.target;
    //     setAppointmentData((prevData: any) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };
    return (
        <div>
            <Dialog
                visible={showAppointmentModal}
                style={{ width: "40rem" }}
                headerClassName="text-800 font-semibold text-xl"
                header={"Update Appointment"}
                breakpoints={{ "960px": "75vw", "640px": "90vw" }}
                onHide={() => {
                    setShowAppointmentModal(false)
                    setAppointmentData(null)
                }}
            >
                <form onSubmit={handleAppointmentSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Status</label>
                        <select
                            name="status"
                            value={appointmentData?.status}
                            onChange={(e) => inputHandler(e, appointmentData, setAppointmentData)}
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                        >
                            {statusOptions?.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option?.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Type</label>
                        <select
                            name="type"
                            value={appointmentData?.type}
                            onChange={(e) => inputHandler(e, appointmentData, setAppointmentData)}
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                        >
                            {appointmentTypeOptions?.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option?.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Date</label>
                        <input
                            type="date"
                            name="date"
                            value={formatDate(appointmentData?.date)}
                            onChange={(e) => inputHandler(e, appointmentData, setAppointmentData)}
                            className="w-full px-3 py-2 border rounded-md focus:outline-none"
                        />
                    </div>

                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="px-4 py-2 mr-2 bg-gray-400 text-white rounded-md"
                            onClick={() => setShowAppointmentModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-primary text-white rounded-md"
                            disabled={loading}
                        >
                            Update
                        </button>
                    </div>
                </form>
            </Dialog>
        </div>
    );
};

export default AppointmentUpdateModal;