import React from 'react';
import { Sidebar } from "primereact/sidebar";
import { payloadDateFormat } from "../../utils/helper";
import { statusOptions } from "../../utils/staticData";

const SidebarFilterMenu = ({ showFilter, setShowFilter, setFromDate, setToDate, fromDate, toDate, selectedStatus, setSelectedStatus, isStatus }: any) => {
    const handleStatusChange = (status: string) => {
        setSelectedStatus((prevSelectedStatus: any) =>
            prevSelectedStatus === status ? '' : status
        );
    };
    return (
        <div>
            <Sidebar visible={showFilter} onHide={() => {
                setShowFilter(false)
            }}>
                <div>
                    <div className="">
                        <p className="text-center border-b border-b-primary font-semibold">Date Filter</p>
                    </div>
                    <div className="p-4">

                        <div className="space-y-2">
                            <div className="flex justify-between items-center">
                                <label htmlFor="">From</label>
                                <input
                                    type="date"
                                    value={payloadDateFormat(fromDate)}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    className="border rounded px-3 py-2 focus:outline-none cursor-pointer"
                                />
                            </div>
                            <div className="flex justify-between items-center">
                                <label htmlFor="">To</label>
                                <input
                                    type="date"
                                    value={payloadDateFormat(toDate)}
                                    onChange={(e) => setToDate(e.target.value)}
                                    className="border rounded px-3 py-2 focus:outline-none cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isStatus && <div>
                        <p className="text-center border-b border-b-primary font-semibold">Status Filter</p>

                        <div className="p-4">
                            {statusOptions?.map((status) => (
                                <div key={status.value} className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        id={status.value}
                                        value={status.value}
                                        checked={selectedStatus === status.value}
                                        onChange={() => handleStatusChange(status.value)}
                                        className="mr-2 cursor-pointer"
                                    />
                                    <label htmlFor={status.value} className="text-gray-700">
                                        {status.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                }


            </Sidebar>
        </div>
    );
};

export default SidebarFilterMenu;