import { createBrowserRouter } from "react-router-dom";
import Root from "../Root/Root";
import Login from "../Page/Login/Login";
import Error from "../Page/Error/Error";
import Loader from "../components/Loader/Loader";
import UserRoute from "../Auth/UserRoute/UserRoute";
import CreateAppointment from "../components/MainPage/Components/WaitingRoom/Components/CreateAppointment/CreateAppointment";
import MainPage from "../components/MainPage/MainPage";
import Profile from "../Page/Profile/Profile";
export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <UserRoute>
        <Root />
      </UserRoute>
    ),
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <MainPage />,
      },
      {
        path: "/loader",
        element: <Loader />,
      },
      {
        path: "/create-appointment",
        element: <CreateAppointment />,
      },
      {
        path: "/profile",
        element: <Profile />,
      }

    ],
  },
  {
    path: "/login",
    element: <Login />,
  },

]);


