import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Initial state, use 'null' as the default fallback instead of empty strings
const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  userRoles: JSON.parse(localStorage.getItem("userRoles")) || null,
  isLoggedIn: !!JSON.parse(localStorage.getItem("user"))?.token,
  isLoading: false,
  authError: null,
  selectedRole: JSON.parse(localStorage.getItem("selectedRole")) || null,
  selectedDoctor: JSON.parse(localStorage.getItem("selectedDoctor")) || null,
};

// Thunk for login action
export const login = createAsyncThunk(
  "auth/login",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = await post("/login", payload);
      // if (response?.status === 200) {
      //   return response.data;
      // } else {
      //   return rejectWithValue(response.data);
      // }
    } catch (error) {
      // Improved error handling
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Removing user info from local storage and resetting state
    removeAuthInfo: (state) => {
      localStorage.removeItem("user");
      localStorage.removeItem("userRoles");
      localStorage.removeItem("selectedRole");
      state.user = null;
      state.userRoles = null;
      state.isLoggedIn = false;
      state.selectedRole = null;
    },

    // Setting auth info into local storage and state
    setAuthInfo: (state, action) => {
      const { userRoles, ...user } = action.payload;
      state.user = user;
      state.userRoles = userRoles;

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userRoles", JSON.stringify(userRoles));

      if (userRoles?.length > 0) {
        state.selectedRole = userRoles[0];
        localStorage.setItem("selectedRole", JSON.stringify(userRoles[0]));
      }
    },

    // Manually set selected role and store in localStorage
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
      localStorage.setItem("selectedRole", JSON.stringify(action.payload));
    },

    setSelectedDoctor: (state, action) => {
      state.selectedDoctor = action.payload;
      localStorage.setItem("selectedDoctor", JSON.stringify(action.payload));
    },

    // Update user roles and save in localStorage
    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
      localStorage.setItem("userRoles", JSON.stringify(action.payload));
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.authError = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload?.user && action.payload?.token) {
          const { userRoles, ...user } = action.payload;
          state.user = user;
          state.userRoles = userRoles || [];

          if (userRoles?.length > 0) {
            state.selectedRole = userRoles[0];
            localStorage.setItem("selectedRole", JSON.stringify(userRoles[0]));
          }

          state.isLoggedIn = true;
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("userRoles", JSON.stringify(userRoles));
        }
        state.authError = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.authError = action.payload?.message || "Login failed";
      });
  },
});

// Exporting actions and reducer
export const { removeAuthInfo, setAuthInfo, setSelectedRole, setUserRoles, setSelectedDoctor } =
  authSlice.actions;

export default authSlice.reducer;
