import React from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import "primereact/resources/primereact.css";
import Tabs from "../Tab/Tab";
import {FaHome, FaUser} from "react-icons/fa";
import WaitingRoom from "./Components/WaitingRoom/WaitingRoom";
import CompletedAppointment from './Components/CompletedAppointment/CompletedAppointment';

const MainPage = () => {
    const tabs = [
        {
            icon: <FaHome />,
            label: 'Waiting Room',
            content: <WaitingRoom/>,
        },
        {
            icon: <FaUser />,
            label: 'Completed Appointment',
            content: <CompletedAppointment/>,
        },

    ];
    return (
        <div className='container mx-auto mt-5'>
           <Tabs items={tabs}/>
        </div>
    );
};

export default MainPage;