import { Navigate, useLocation } from "react-router-dom";
import { useStore } from "../../GlobalProvider/GlobalProvider";
import Loader from "../../components/Loader/Loader";

const UserRoute = ({ children }: any) => {
  const location = useLocation();
  const { loader, token } = useStore();
  if (loader) {
    return <Loader />;
  }
  if (token?.length > 1) {
    return children;
  }
  return <Navigate to="/login" state={{ from: location }} />;
};

export default UserRoute;
