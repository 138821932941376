import {useState} from "react";
import {FaRegEdit} from "react-icons/fa";
import {useStore} from "../../GlobalProvider/GlobalProvider";
import {baseUrl, formattedDateTime, getStatusColor, getTypeColor} from "../../utils/helper";
import {LiaUserEditSolid} from "react-icons/lia";
import {MdAddToHomeScreen, MdDelete} from "react-icons/md";
import { IoAccessibility } from "react-icons/io5";
import AppointmentUpdateModal from "../Modals/AppointmentUpdateModal/AppointmentUpdateModal";
import PatientUpdateModal from "../Modals/PatientUpdateModal/PatientUpdateModal";
import VisitModal from "../Modals/VisitModal/VisitModal";
import VitalSigns from "../vital-signs/vital-signs";

const Card = ({data, update, setUpdate}: any) => {
    const {
        officeLocation,
        doctorName,
        serial,
        patientId,
        patientName,
        patientPhone,
        patientDob,
        patientGender,
        nextVisitInstruction,
        date,
        patientAddress,
        type,
        status,
        _id,
    } = data;

    const {token} = useStore();
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [showVisit, setShowVisit] = useState(false);
    const [showPatientModal, setShowPatientModal] = useState(false);
    const [appointmentData, setAppointmentData] = useState<any>();
    const [patientData, setPatientData] = useState<any>();
    const [show, setShow] = useState(false);
    const [vitals, setVitals] = useState<any>([]);

    const [visitData, setVisitData] = useState({
        id: '',
        reasonForVisit: '',
        historyOfPresetIllness: ''

    })
    const [loading, setLoading] = useState(false);

    const handleAppointmentSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response: any = await appointmentUpdateMethod({
                type: appointmentData?.type,
                status: appointmentData?.status,
                date: appointmentData?.date
            })


            if (response?.ok) {
                setUpdate(!update);
                setShowAppointmentModal(false);
            } else {
                console.error('Update failed');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const handlePatientSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const updatePayload = {
            name: patientData?.name,
            phone: patientData?.phone,
            dob: patientData?.dob,
            gender: patientData?.gender,
        }
        try {
            const response = await fetch(`${baseUrl}/patient/update/${patientId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(updatePayload),
            });

            if (response.ok) {
                let res = await appointmentUpdateMethod({
                    patientName: patientData?.name,
                    patientPhone: patientData?.phone,
                    patientDob: patientData?.dob,
                    patientGender: patientData?.gender,
                    patientAddress:patientData?.address
                })
                if (res.ok) {
                    setUpdate(!update);
                    setShowPatientModal(false);
                }
            } else {
                console.error('Update failed');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const appointmentUpdateMethod = async (payload: any) => {
        return await fetch(`${baseUrl}/appointment/update/${_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        });
    }
    const handleVisit = async (id: any) => {
        try {
            const response = await fetch(`${baseUrl}/appointment/create-visit/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const resData = await response.json();
            if (response.ok) {
                setVisitData({
                    id: resData?._id,
                    reasonForVisit: resData?.reasonForVisit ? resData?.reasonForVisit : '',
                    historyOfPresetIllness: resData?.historyOfPresetIllness ? resData?.historyOfPresetIllness : ''
                });
                setVitals(resData?.vitals)
            } else {
                console.error('Update failed');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlevisitSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const Payload = {
            reasonForVisit: visitData?.reasonForVisit,
            historyOfPresetIllness: visitData?.historyOfPresetIllness
        }
        try {
            const response = await fetch(`${baseUrl}/visit/${visitData?.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(Payload),
            });

            if (response.ok) {
                setUpdate(!update);
                setShowVisit(false);
            } else {
                console.error('Update failed');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleVitalsSubmit = async (payload:any,e: any,) => {
        // e.preventDefault();
        // setLoading(true);
        try {
            const response = await fetch(`${baseUrl}/visit/${visitData?.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                setUpdate(!update);
                setShow(false);
            } else {
                console.error('Update failed');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            // setLoading(false);
        }
    };

    const handleDelete = async (id:any) => {

        try {
            const response = await fetch(`${baseUrl}/appointment/remove/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setUpdate(!update);
            } else {
                console.error('Update failed');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            // setLoading(false);
        }
    };

    return (
        <div>
            <div
                className="max-w-md mx-auto bg-white shadow-lg rounded-md overflow-hidden border border-primary h-full flex flex-col">
                <div className="p-4 flex-grow">
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold text-gray-600 mb-2">{patientName}</p>
                        {
                            patientId && <LiaUserEditSolid
                                className="text-primary cursor-pointer"
                                onClick={() => {
                                    setShowPatientModal(true)
                                    setPatientData({
                                        name: patientName,
                                        phone: patientPhone,
                                        dob: patientDob,
                                        gender: patientGender,
                                        address:patientAddress
                                    })
                                    // setAppointmentData({
                                    //     id: _id,
                                    //     status: status,
                                    //     type: type,
                                    //     date: date
                                    // })
                                }}
                                size={22}
                            />
                        }

                    </div>

                    <h2 className="text-sm mb-2">{officeLocation?.name}</h2>
                    <p className="text-sm text-gray-600 mb-2">Doctor: {doctorName}</p>
                    <p className="text-sm text-gray-600 mb-2">Patient ID: {patientId}</p>
                    <p className="text-sm text-gray-600 mb-2">Phone: {patientPhone}</p>
                    <p className="text-sm text-gray-600 mb-2">DOB: {formattedDateTime(patientDob)}</p>
                    <p className="text-sm text-gray-600 mb-2">Gender: {patientGender}</p>
                    {patientAddress&&<p className="text-sm text-gray-600 mb-2">Address: {patientAddress}</p>}
                    <p className="text-sm text-gray-600 mb-2">Appointment Date: {formattedDateTime(date)}</p>
                    <p className="text-sm text-gray-600 mb-2">Serial: {serial}</p>
                    <p className="text-sm text-gray-600 mb-2">Type: <span
                        className={`${getTypeColor(type)} font-bold`}>{type}</span></p>
                    <p className="text-sm text-gray-600 mb-2">Status: <span
                        className={`${getStatusColor(status)} font-bold`}>{status}</span></p>
                    {nextVisitInstruction &&
                        <p className="text-sm text-gray-600 mb-2">Next Visit Instruction: {nextVisitInstruction}</p>}
                    <div className="flex items-center justify-between">
                        <div className="">
                            <label className="text-sm font-bold ">Appointment Update</label>
                            <div className="flex items-center gap-2">
                                <FaRegEdit
                                    className="text-primary cursor-pointer mr-4"
                                    onClick={() => {
                                        setShowAppointmentModal(true)
                                        setAppointmentData({
                                            id: _id,
                                            status: status,
                                            type: type,
                                            date: date
                                        })
                                    }}
                                    size={22}
                                />
                                <MdDelete size={22} className="text-red-500  cursor-pointer" onClick={()=>{
                                    handleDelete(_id)
                                }}/>
                            </div>

                        </div>
                        <div>
                            <label className="text-sm font-bold ">Patient Visit</label>
                            <div className="text-primary flex">
                                <MdAddToHomeScreen
                                    className="text-primary cursor-pointer mx-3"
                                    size={22}
                                    onClick={() => {
                                        setShowVisit(true)
                                        handleVisit(_id)
                                    }}
                                />
                                <IoAccessibility
                                    className="text-primary cursor-pointer"

                                    size={22}
                                    onClick={() => {
                                        setShow(true)
                                        handleVisit(_id)
                                    }}
                                />
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <AppointmentUpdateModal
                showAppointmentModal={showAppointmentModal}
                setShowAppointmentModal={setShowAppointmentModal}
                handleAppointmentSubmit={handleAppointmentSubmit}
                appointmentData={appointmentData}
                setAppointmentData={setAppointmentData}
                loading={loading}

            />
            <PatientUpdateModal
                showPatientModal={showPatientModal}
                setShowPatientModal={setShowPatientModal}
                handlePatientSubmit={handlePatientSubmit}
                setPatientData={setPatientData}
                patientData={patientData}
                loading={loading}
            />
            <VisitModal
                showVisit={showVisit}
                setShowVisit={setShowVisit}
                handlevisitSubmit={handlevisitSubmit}
                visitData={visitData}
                setVisitData={setVisitData}
                loading={loading}
            />
            <VitalSigns handleVitalsSubmit={handleVitalsSubmit} vitals={vitals} show={show} setShow={setShow}/>


        </div>
    );
};

export default Card;