import React, {useMemo, useState} from "react";
import "./vital-sign-form.scss";
import {Form, Formik} from "formik";

const moment = require("moment");

const VitalSignForm = ({previousVitals, handleVitalsSubmit}) => {

    const [info, setInfo] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const setInfoFun = (key, value) => {
        let temp = {...info};
        setInfo({...temp, [key]: value});
    };


    function getPayload(payload = {}) {
        payload["vitals.height"] = ((+info?.height_ft || 0) + "." + ((+info?.height_inc || 0))) || "";
        payload["vitals.weight"] = ((+info?.weight_kg || 0) + ((+info?.weight_gram || 0) / 1000))?.toFixed(2)?.toString() || "";
        payload["vitals.bmi"] = info?.bmi || "";
        payload["vitals.bp"] = (info?.bp_upper || "") + "/" + (info?.bp_lower || "");
        payload["vitals.hr"] = info?.hr || "";
        payload["vitals.temperature"] = info?.temperature || "";
        payload["vitals.spo2"] = info?.spo2 || "";
        payload["vitals.rr"] = info?.rr || "";
        payload["vitals.note"] = info?.note || "";
        payload["vitals.date"] = moment().format("YYYY-MM-DD");
        return payload
    }

    const onSubmit = async (values) => {
        handleVitalsSubmit( getPayload(values))
    };


    useMemo(() => {
        if (previousVitals) {
            let bp = previousVitals?.bp?.split("/")
            let height = previousVitals?.height?.split(".")
            setInfo({
                weight_kg: parseInt(previousVitals?.weight) || "",
                weight_gram: previousVitals?.weight * 1000 - parseInt(previousVitals?.weight) * 1000 || "",
                height_ft: height?.length > 0 ? height[0] : "",
                height_inc: height?.length > 1 ? height[1] : "",
                bp_upper: bp?.length > 0 ? bp[0] : "",
                bp_lower: bp?.length > 1 ? bp[1] : "",
                hr: previousVitals?.hr || "",
                bmi: previousVitals?.bmi || "",
                temperature: previousVitals?.temperature || "",
                spo2: previousVitals?.spo2 || "",
                rr: previousVitals?.rr || "",
            })
        }
    }, [previousVitals])

    useMemo(() => {
        if (
            (info.height_ft || info.height_inc) &&
            (info.weight_gram || info.weight_kg)
        ) {
            let h =
                (info?.height_ft || 0) * 0.3048 + (+info?.height_inc || 0) * 0.0254;

            let w = (+info?.weight_kg || 0) + (+info?.weight_gram || 0) / 1000;
            const bmi = w / Math.pow(h, 2);
            setInfoFun("bmi", bmi?.toFixed(2));
        } else setInfoFun("bmi", "");
    }, [info.height_ft, info.height_inc, info.weight_gram, info.weight_kg]);


    return (
        <div className="vital-signs">
            <div className="vital-signs-form">
                {" "}
                <Formik initialValues={{}} onSubmit={onSubmit}>
                    {(formik) => (
                        <div className="appointment-create-form-section">
                            <Form
                                action=""
                                id=""
                                className="custom-form input-section position-relative"
                                encType="multipart/form-data"
                            >
                                <div className="row  ">
                                    <div className="col-6 col-md-6 mb-0 pb-0 individual-input-section">
                                        <label className="form-label">Height</label>
                                        <div className="d-flex">
                                            <input
                                                className="form-control input w-25"
                                                autoComplete="off"
                                                type="number"
                                                value={info?.height_ft || ""}
                                                placeholder="ft"
                                                onChange={(e) => {
                                                    setInfoFun("height_ft", e.target.value);
                                                }}
                                            />
                                            <span className="mx-2 text-secondary">ft</span>
                                            <input
                                                className="form-control input w-25"
                                                autoComplete="off"
                                                type="number"
                                                value={info?.height_inc || ""}
                                                placeholder="inc"
                                                onChange={(e) => {
                                                    setInfoFun("height_inc", e.target.value);
                                                }}
                                            />
                                            <span className="mx-2 text-secondary">inches</span>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 individual-input-section">
                                        <label htmlFor="exampleInputEmail1" className="form-label">
                                            Weight
                                        </label>
                                        <div className="d-flex">
                                            <input
                                                className="form-control input w-25"
                                                autoComplete="off"
                                                type="number"
                                                value={info?.weight_kg || ""}
                                                placeholder="kg"
                                                onChange={(e) => {
                                                    setInfoFun("weight_kg", e.target.value);
                                                }}
                                            />
                                            <span className="mx-2 text-secondary">kg</span>
                                            <input
                                                className="form-control input w-25"
                                                autoComplete="off"
                                                type="number"
                                                value={info?.weight_gram || ""}
                                                placeholder="g"
                                                onChange={(e) => {
                                                    setInfoFun("weight_gram", e.target.value);
                                                }}
                                            />
                                            <span className="mx-2 text-secondary">gram</span>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6  mb-0 pb-0 individual-input-section">
                                        <label htmlFor="exampleInputEmail1" className="form-label">
                                            BMI
                                        </label>
                                        <div className="d-flex">
                                            {" "}
                                            <input
                                                className="form-control input w-50"
                                                autoComplete="off"
                                                type="text"
                                                id="fs"
                                                name="fs"
                                                placeholder="bmi"
                                                value={info.bmi || ""}
                                                onChange={(e) => {
                                                    setInfoFun("bmi", e.target.value);
                                                }}
                                            />
                                            <span className="ml-2 text-secondary">ct</span>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 mb-0 pb-0 individual-input-section">
                                        <label htmlFor="exampleInputEmail1" className="form-label">
                                            Temperature
                                        </label>
                                        <div className="d-flex">
                                            <input
                                                className="form-control input w-75"
                                                autoComplete="off"
                                                //   pattern="(^(01){1}[3-9]{1}\d{8})$"
                                                type="number"
                                                placeholder="fahrenheit"
                                                value={info.temperature || ""}
                                                onChange={(e) => {
                                                    setInfoFun("temperature", e.target.value);
                                                }}
                                            />
                                            <span className="text-secondary mx-2">F</span>
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-6  mb-0 pb-0 individual-input-section">
                                        <label htmlFor="exampleInputEmail1" className="form-label">
                                            Blood pressure
                                        </label>
                                        <div className="d-flex">
                                            <div className="d-flex">
                                                <input
                                                    className="form-control input w-25"
                                                    autoComplete="off"
                                                    type="number"
                                                    value={info?.bp_upper || ""}
                                                    placeholder="Top"
                                                    onChange={(e) => {
                                                        setInfoFun("bp_upper", e.target.value);
                                                    }}
                                                />
                                                <span className="mx-2 text-secondary">/</span>
                                                <input
                                                    className="form-control input w-25"
                                                    autoComplete="off"
                                                    type="number"
                                                    value={info?.bp_lower || ""}
                                                    placeholder="Bottom"
                                                    onChange={(e) => {
                                                        setInfoFun("bp_lower", e.target.value);
                                                    }}
                                                />
                                                <span className="mx-2 text-secondary">mmHg</span>
                                            </div>
                                        </div>

                                        {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                                    </div>
                                    <div className="col-6 col-md-6  mb-0 pb-0 individual-input-section">
                                        <label htmlFor="exampleInputEmail1" className="form-label">
                                            Heart rate
                                        </label>
                                        <div className="d-flex">
                                            {" "}
                                            <input
                                                className="form-control input w-50"
                                                autoComplete="off"
                                                type="number"
                                                placeholder="Hr"
                                                value={info.hr || ""}
                                                onChange={(e) => {
                                                    setInfoFun("hr", e.target.value);
                                                }}
                                            />
                                            <span className="ml-2 text-secondary">/ min</span>
                                        </div>
                                        {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                                    </div>
                                    <div className="col-6 col-md-6  mb-0 pb-0 individual-input-section">
                                        <label htmlFor="exampleInputEmail1" className="form-label">
                                            SPO2
                                        </label>
                                        <div className="d-flex">
                                            {" "}
                                            <input
                                                className="form-control input w-50"
                                                autoComplete="off"
                                                type="number"
                                                placeholder="SPO2"
                                                value={info.spo2 || ""}
                                                onChange={(e) => {
                                                    setInfoFun("spo2", e.target.value);
                                                }}
                                            />
                                            <span className="ml-2 text-secondary">%</span>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6  mb-0 pb-0 individual-input-section">
                                        <label htmlFor="exampleInputEmail1" className="form-label">
                                            Respiratory rate
                                        </label>
                                        <div className="d-flex">
                                            {" "}
                                            <input
                                                className="form-control input w-50"
                                                autoComplete="off"
                                                type="number"
                                                placeholder="Rr"
                                                value={info.rr || ""}
                                                onChange={(e) => {
                                                    setInfoFun("rr", e.target.value);
                                                }}
                                            />
                                            <span className="ml-2 text-secondary">/ min</span>
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-12  mb-0 pb-0 individual-input-section">
                                        <label htmlFor="exampleInputEmail1" className="form-label">
                                            Additional note
                                        </label>
                                        <div className="d-flex">
                                            {" "}
                                            <textarea
                                                className="form-control  w-100"
                                                autoComplete="off"
                                                type="text"
                                                rows={4}
                                                id="note"
                                                name="note"
                                                placeholder="Additional note"
                                                value={info.note || ""}
                                                onChange={(e) => {
                                                    setInfoFun("note", e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12  text-center mt-3 d-flex">
                                        <div className="flex justify-center">
                                            <button
                                                type="submit"
                                                className="px-12 py-2 bg-primary text-white rounded-md"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default VitalSignForm;
