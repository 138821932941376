import { useEffect, useState } from "react";
import { calculateNextDate, calculatePreviousDate } from "../utils/helper";

const useData = () => {

  const [show, setShow] = useState(true);
  const [loader, setLoader] = useState(true);
  const [token, setToken] = useState<any>(null);
  const [userProfile, setUserProfile] = useState<any>(null)
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [userProfileId, setUserPorfileId] = useState<number|null|any>(null)
  const [search, setSearch] = useState<string>()
  const [fromDate, setFromDate] = useState<any>(new Date());
  const [toDate, setToDate] = useState<any>(new Date());

  const [completedSearch, setCompletedSearch] = useState<string>()
  const [completedFromDate, setCompletedFromDate] = useState<any>(calculatePreviousDate(3));
  const [completedToDate, setCompletedToDate] = useState<any>(calculateNextDate(3));

  useEffect(() => {
    setLoader(false);
    const getToken = localStorage.getItem('token');
    setToken(getToken)
    const getUserProfileID = localStorage.getItem('profile_id')
    setUserPorfileId(getUserProfileID)
  }, []);
  return {
    show,
    setShow,
    loader,
    setLoader,
    token,
    setToken,
    userProfile, setUserProfile, userProfileId, setUserPorfileId,selectedStatus, 
    setSelectedStatus,search, setSearch,fromDate, setFromDate,toDate, setToDate,completedSearch, setCompletedSearch,
    completedFromDate, setCompletedFromDate,
    completedToDate, setCompletedToDate

  };
};

export default useData;
