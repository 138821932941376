import moment from "moment";
import {Toast} from "primereact/toast";
export const showLog = ()=>{
    console.log("hello helper")
}

export const payloadDateFormat = (date: string | Date) => {
    return moment(date).format('YYYY-MM-DD')
}
export default function callToast(
    toast: React.RefObject<Toast | null>,
    type: boolean | string,
    msg: string,
    life: number = 3000
) {
    if (toast?.current) {
        toast?.current?.show({
            severity: type === "warn" ? "warn" : type ? "success" : "error",
            summary: type === "warn" ? "Warning" : type ? "Successful" : "Error",
            detail: msg,
            life: life,
        });
    }
}

export const formattedDateTime = (date: string | Date, isTime: boolean = false) => {
    return moment(date).format(`${isTime ? `h:mm A,` : ""} ddd, MMMM Do YYYY`)
}


export const calculatePreviousDate = (days:number) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().split('T')[0]; 
  };
  export const calculateNextDate = (days:number) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date.toISOString().split('T')[0]; 
  };

export  const baseUrl = process.env.REACT_APP_API_BASE_URL

export   const inputHandler = (e: any, state: {} | undefined, setState: any) => {
    setState({
        ...state,
        [e.target.name]: e.target.value,
    });
};

export const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '';
    return date.toISOString().split('T')[0];
  }

export const getStatusColor = (status: string) => {
    switch (status) {
        case 'present':
            return 'text-blue-500';
        case 'pending':
            return 'text-yellow-500';
        case 'completed':
            return 'text-primary';
        case 'canceled':
            return 'text-red-500';
        default:
            return 'text-gray-600';
    }
};
export const getTypeColor = (type: string) => {
    switch (type) {
        case 'new':
            return 'text-blue-500';
        case 'old':
            return 'text-yellow-500';
        case 'followup':
            return 'text-primary';
        default:
            return 'text-gray-600';
    }
};