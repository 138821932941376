import React from 'react';
import Loader from "../Loader/Loader";
import Card from "../Card/Card";
import NoData from "../NoData/NoData";
import {Button} from "primereact/button";

const AppointmentList = ({appointmentData,loading,update,setUpdate,totalPages,handlePageChange,currentPage}:any) => {

    const handlePageChangeAndScroll = (page: number) => {
        handlePageChange(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <div>
            <>{
                    appointmentData?.length > 0 ?
                        <div>
                            {
                                loading ? <Loader/> :
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 mt-5">
                                        {appointmentData?.map((appointment: any, index: number) => (
                                            <Card key={index} data={appointment} update={update} setUpdate={setUpdate}/>
                                        ))}
                                    </div>
                            }
                        </div> : <NoData/>
                }
            </>
            {
                !loading && <div className="flex justify-center items-center gap-2 mt-4">
                    {Array.from({length: totalPages}, (_, index) => (
                        <Button
                            key={index}
                            onClick={() => handlePageChangeAndScroll(index + 1)}
                            label={String(index + 1)}
                            className="bg-primary rounded-full text-sm w-7 h-7 cursor-pointer text-white"
                            disabled={currentPage === index + 1}
                        />
                    ))}
                </div>
            }

        </div>
    );
};

export default AppointmentList;
