import React from 'react';
import "./existingPatient.scss"

const ExistingPatient = ({setSearchKey,searchKey, patientSearch, setPatientSearch, Patients, handlePatientSelect }: any) => {
    
    return (
        <div>
            <div className="p-4 bg-white shadow-lg rounded-md existing-patient">

                <div className='flex justify-end'>
                    <select
                        onChange={(e) => {
                            setSearchKey(e.target.value)
                        }}
                        value={searchKey}
                        className=''
                    >
                        <option value={'patientId'}>By patient id</option>
                        <option value={'name'}>By name</option>
                    </select>{" "}
                    <input
                        className="lg:w-1/2 px-3 py-2 mb-4 border rounded-md focus:outline-none"
                        type="text"
                        placeholder="Search by name..."
                        value={patientSearch}
                        onChange={(e) => setPatientSearch(e.target.value?.trim())}
                    />
                </div>
                {Patients?.length > 0 && (
                    <div className="container mx-auto p-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                            {Patients?.map((patient: any) => (
                                <div key={patient._id}
                                    className="bg-white shadow-lg rounded-md overflow-hidden border border-primary mb-4 p-4">
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4">
                                        <div className='flex items-center gap-2'>
                                            <p className="text-sm md:text-base font-semibold">Name:</p>
                                            <p className="text-sm md:text-base text-gray-700">{patient?.name}</p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <p className="text-sm md:text-base font-semibold">Patient Id:</p>
                                            <p className="text-sm md:text-base text-gray-700">{patient?.patientId}</p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <p className="text-sm md:text-base font-semibold">Phone:</p>
                                            <p className="text-sm md:text-base text-gray-700">{patient?.phone}</p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <p className="text-sm md:text-base font-semibold">Email:</p>
                                            <p className="text-sm md:text-base text-gray-700">{patient?.email}</p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <p className="text-sm md:text-base font-semibold">Gender:</p>
                                            <p className="text-sm md:text-base text-gray-700">{patient?.gender}</p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <p className="text-sm md:text-base font-semibold">Blood Group:</p>
                                            <p className="text-sm md:text-base text-gray-700">{patient?.bloodGroup}</p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <p className="text-sm md:text-base font-semibold">Marital Status
                                                :</p>
                                            <p className="text-sm md:text-base text-gray-700">{patient?.maritalStatus
                                            }</p>
                                        </div>
                                        <div className="col-span-1 flex items-center justify-center mt-4 md:mt-0">
                                            <button
                                                className="px-4 py-2 bg-primary text-white rounded text-sm md:text-base"
                                                onClick={() => handlePatientSelect(patient)}
                                            >
                                                Select
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>


                )}
            </div>
        </div>
    );
};

export default ExistingPatient;