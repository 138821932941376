import React from 'react';

const NoData = () => {
    return (

        <div className='container mx-auto'>
        

   <div className="flex justify-center items-center mt-5">
                    <img className='w-44 h-28' src={require("../../assets/error/nodata.png")} alt='' />

                </div>

         
        </div>
    );
};

export default NoData;